import { FunctionComponent } from "react";
import ReactMarkdown from "react-markdown";
import styled from "@emotion/styled";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/cjs/styles/prism";

const CodeRenderer = (props: { language: string, value: string}) => {
  const { language, value } = props;
  return (
    <SyntaxHighlighter language={language} style={coy}>
      {value}
    </SyntaxHighlighter>
  )
}

const ImageRenderer = (props: any) => {
  const youtubeRegExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = props.src.match(youtubeRegExp);
  const isYoutube = match && match[2].length === 11;
  if (isYoutube) {
    return (
      <iframe src={`https://www.youtube.com/embed/${match[2]}`} allow="autoplay; encrypted-media" allowFullScreen />
    )
  } else {
    return <img {...props} />;
  }
}

interface Props {
  body: string;
}
export const Markdown: FunctionComponent<Props> = props => {
  return (
    <StyledReactMarkdown
    escapeHtml={false}
      source={props.body}
      renderers={{
        code: CodeRenderer,
        image: ImageRenderer,
      }}
    />
  )
}

const StyledReactMarkdown = styled(ReactMarkdown)`
  -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
          box-sizing: border-box;

  color: #333;
  font-size: 18px;
  line-height: 1.6em;

  blockquote,
  ol,
  ul,
  p,
  pre,
  table {
    margin-top: 0;
    margin-bottom: 16px;
  }

  /** headers */

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.875em;
  }

  h6 {
    font-size: 0.85em;
  }


  /** emphasis */

  em {
    font-style: italic;
  }

  strong {
    font-weight: bolder;
  }


  /** list */

  ol,
  ul
  {
    padding-left: 2em;
  }

  li {
    word-wrap: break-all;

    /* p {
      margin-top: 16px;
    } */
  }

  li+li {
    margin-top: .25em;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  ol ol,
  ul ol {
    list-style-type: lower-roman;
  }

  ol ol ol,
  ol ul ol,
  ul ol ol,
  ul ul ol {
    list-style-type: lower-alpha;
  }


  /** image */

  img {
    display: block;
    margin: 3rem auto;
    max-width: 100%;
  }


  /** video */

  iframe {
    display: block;
    margin: 3rem auto;
    max-width: 100%;
    width: 768px;
    height: 432px;
    border: 0;
  }


  /** link */

  a {

    :hover {
      text-decoration: underline;
    }
  }


  /** blockquote */

  blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: .25em solid #dfe2e5;
  }


  /** inline code */
  
  code {
    padding: .2em .4em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(27,31,35,.05);
    border-radius: 3px;
  }


  /** code block */

  pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;

    code {
      padding: 0;
      margin: 0;
      font-size: 100%;
      word-break: normal;
      white-space: pre;
      background: transparent;
      border: 0;
    }
  }


  /** task list */

  input[type=checkbox] {

  }

  /** table */

  table {
    /* border-spacing: 0;
    border-collapse: collapse; */

    display: block;
    width: 100%;
    overflow: auto;

    th {
      font-weight: 600;
    }

    td,
    th {
      padding: 6px 13px;
      border: 1px solid #dfe2e5;
    }

    tr {
      background-color: #fff;
      border-top: 1px solid #c6cbd1;
    }

    tr:nth-child(2n) {
      background-color: #f6f8fa;
    }
  }

  /** strikethrough */

  del {

  }


  hr {
  height: .25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0;
  }
`;
